<!-- 模块说明 -->
<template>
  <div class="container">
    <subHead :active="2" :subpageActive="activeName"></subHead>
    <div class="banner">
      <div class="title">
        <h5>企业案例</h5>
        <p>Business Case</p>
      </div>
    </div>
    <template v-if="!details">
      <div class="tabs">
        <div class="content">
          <div
            class="typeList"
            :class="{ on: activeName == item.id }"
            v-for="(item, index) in typeList"
            :key="index"
            @click="activeName = item.id"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="content" style="margin-bottom: 150px">
        <div
          class="hotspotList flex"
          v-for="(item, index) in typeList[activeName].hotspotList"
          :key="index"
          @click="jumpTo(index)"
        >
          <div class="left flex">
            <el-image :src="item.img" fit="cover"></el-image>
            <div class="wnzi">
              <div class="title">{{ item.name }}</div>
              <div v-if="item.type || item.mode" class="tips">
                <div v-if="item.type" class="type">
                  输送人员类型：{{ item.type }}
                </div>
                <div v-if="item.mode" class="mode">
                  合作模式：{{ item.mode }}
                </div>
              </div>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="crumbs">
        <div class="content">
          当前位置： <span @click="$router.push('/')">网站首页</span> >
          <span @click="jumpTo()">{{ typeList[activeName].name }}</span> >
          {{ typeList[activeName].name }}详情
        </div>
      </div>
      <div class="news">
        <div class="content">
          <div class="left">
            <div class="operate">
              <div v-if="show > 0" @click="reduce" class="view">
                <i class="el-icon-arrow-up"></i>
                <span>上一篇</span>
              </div>
              <div class="view" @click="jumpTo()">
                <i class="el-icon-menu"></i>
                <span>返回列表</span>
              </div>
              <div v-if="show < 2 && show !== 0" @click="add" class="view">
                <i class="el-icon-arrow-down"></i>
                <span>下一篇</span>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="view FadeIn">
              <div class="title">
                {{ typeList[activeName].hotspotList[show].name }}
              </div>
              <div
                v-if="typeList[activeName].hotspotList[show].mode"
                class="tip"
              >
                合作模式：{{ typeList[activeName].hotspotList[show].mode }}
              </div>
              <div
                v-if="typeList[activeName].hotspotList[show].type"
                class="tip"
              >
                输送人员类型：{{ typeList[activeName].hotspotList[show].type }}
              </div>
              <div class="text">
                {{ typeList[activeName].hotspotList[show].text }}
              </div>
              <img
                :src="typeList[activeName].hotspotList[show].imgText"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import subHead from "../../components/subHead.vue";
export default {
  components: { subHead },
  props: [],
  data() {
    return {
      typeList: [
        {
          name: "岗位外包",
          id: 0,
          hotspotList: [
            {
              name: "京东物流新建厂，共同成长",
              text: "长沙京东物流位于望城经开区，从事电商仓储物流以及运输，我司从企业2017年建厂即产生合作，主要向企业输送人员，保证企业人员配置，在岗过程中我司指派现场管理人员协助安排日常生产计划，装卸以及运输的调度，保证高质高效完成工作任务。我司管理服务人员对企业运营流程清晰，人员工作现场安全指导落实到位，企业活动期间保障每天在职300人，日产量高达10000件以上，得到企业的充分肯定，为后续加强合作打下了坚实的基础。",
              img: require("../../images/home/case/p3.png"),
              type: "社会工",
              mode: "人员外包（分拣、包装、装卸、运输、出货）",
              imgText: require("../../images/home/case/jd.jpg"),
            },
          ],
        },
        {
          name: "业务外包",
          id: 1,
          hotspotList: [
            {
              name: "旺旺产线外包，按时按量完成任务目标",
              text: "2018年进入湖南旺旺食品公司供应商系统，湖南旺旺食品生产的产品包括旺旺大礼包、旺旺碎碎冰、旺旺牛奶系列，旺旺将部分产线委托给我们，我们通过招聘、岗前培训、管理等，组织人员按计划和指标进行生产。服务过程中，严格按照企业生产规章制度与车间时刻保持沟通，自主完成薪酬体系，员工管理体系，源源不断供应劳动力及技术工人，在有限的时间内确保了其投产运营，月产值完成大礼包551860PCS包装运输，旺旺牛奶267585PCS包装运输，我司用专业和服务为企业降本增效，得到企业一致好评。",
              img: require("../../images/home/case/p5.png"),
              type: null,
              mode: "产线外包（包装、装卸、运输）",
              imgText: require("../../images/home/case/ww.jpg"),
            },
          ],
        },
        {
          name: "劳务派遣",
          id: 2,
          hotspotList: [
            {
              name: "戴湘用工技能要求高，人才库匹配度高",
              text: "戴湘汽配科技主要经营铝合金轮毂的研发、制造、批发、零售等，用工技能要求高，出现了招人难等问题，而我们一直与汽车行业保持良好合作，人才库中相关技能人才多，与戴湘匹配度高。我们为戴湘制定了含招聘服务的劳务派遣方案，提供招聘、日常管理、劳动争议等服务，同时提供安全及技能培训服务。日到面50-80人，高峰期月平均在职418人，为戴湘解决用工问题，保障了企业正常生产。",
              img: require("../../images/home/case/p2.png"),
              type: null,
              mode: null,
              imgText: require("../../images/home/case/lw.jpg"),
            },
          ],
        },
        {
          name: "招聘流程外包",
          id: 3,
          hotspotList: [
            {
              name: "蓝思科技用工需求量大，亟待解决",
              text: "蓝思科技是一家集研发、生产、销售显示屏功能玻璃面板于一体的企业，用工需求量大，单靠企业招聘无法满足其用人需求。我们与蓝思科技2014年即开展合作，利用“省内+省外”“学校+社会”资源，开展专场招聘会，进行人员输送，保障高峰期日到面100人，通过各渠道年平均向蓝思科技输送2W人，有力保障了企业正常生产。",
              img: require("../../images/home/case/p4.png"),
              type: "社会工、基层管理员",
              mode: null,
              imgText: require("../../images/home/case/ls.jpg"),
            },
          ],
        },
      ],
      activeName: 0,
      details: false,
      show: 0,
    };
  },
  watch: {
    "$route.query.show": {
      handler(newVal) {
        this.activeName = +newVal || 0;
      },
      deep: true,
    },
  },
  created() {
    this.activeName = +this.$route.query.show;
  },
  methods: {
    jumpTo(i) {
      this.show = i || 0;
      this.details = !this.details;
    },
    reduce() {
      this.show--;
      if (this.show < 0) {
        this.show = 0;
        this.$message("当前已是首篇");
      }
    },
    add() {
      this.show++;
      if (this.show > 2) {
        this.show = 2;
        this.$message("当前已是末篇");
      }
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.news {
  .content {
    .right {
      .view {
        width: 976px;
        // max-height: 700px;
        overflow-y: auto;
        padding-right: 5px;
      }
      img {
        width: 100%;
        display: block;
        margin-bottom: 40px;
      }
      .text {
        font-size: 16px;
        color: #333333;
        padding-bottom: 40px;
        text-indent: 40px;
        line-height: 30px;
      }
      .tip {
        font-size: 16px;
        color: #333333;
        padding-bottom: 20px;
      }
      .title {
        font-size: 28px;
        color: #333333;
        line-height: 38px;
        margin-bottom: 30px;
      }
    }
    .left {
      .operate {
        .view:hover {
          font-weight: bold;
        }
        .view {
          margin-bottom: 40px;
          cursor: pointer;
        }
        span {
          padding-left: 15px;
        }
        i {
          background: #166dba;
          color: white;
          padding: 15px;
          border-radius: 50%;
        }
        font-size: 16px;
        color: #333333;
        margin-top: 63px;
      }
      .tiem {
        .mm {
          span {
            font-size: 25px;
            font-weight: 500;
            padding-left: 5px;
          }
          font-size: 30px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        p {
          width: 80px;
          height: 1px;
          background: #5da5e5;
          margin: 15px auto;
        }
        .yy {
          font-size: 16px;
          margin-top: 28px;
        }
        width: 135px;
        height: 145px;
        background: #166dba;
        text-align: center;
        color: white;
        overflow: hidden;
      }
      margin-right: 85px;
      width: 270px;
    }
    margin: 60px auto 150px;
    display: flex;
  }
}
.hotspotList {
  .tiem {
    .more {
      font-size: 14px;
      color: #999999;
      border: 1px solid #dddddd;
      border-radius: 4px;
      padding: 5px 0;
      width: 66px;
      text-align: center;
    }
    .mm {
      font-size: 30px;
      // font-weight: bold;
      margin: 6px 0 10px;
    }
    .yy {
      font-size: 20px;
      font-weight: bold;
    }
    color: #999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid #eeeeee;
    padding-left: 80px;
  }
  .left {
    .wnzi {
      .tips {
        display: flex;
        font-size: 14px;
        color: #666666;
        .type {
          margin-right: 88px;
        }
        margin-bottom: 20px;
      }
      .text {
        font-size: 14px;
        color: #666666;
        line-height: 25px;
      }
      .title {
        font-size: 20px;
        color: #0e0e0e;
        font-weight: bold;
        margin-bottom: 20px;
        line-height: 35px;
      }
      width: 868px;
      margin-left: 40px;
    }
    .el-image {
      width: 232px;
      height: 192px;
      border-radius: 5px;
      border: 1px solid #dddddd;
      border-radius: 6px;
    }
  }
  padding: 41px 0;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
}
.hotspotList:hover {
  .title {
    color: #166dba !important;
  }
  // border-bottom: 1px solid #166dba;
}
.tabs {
  .typeList {
    padding: 27px 0;
    font-size: 16px;
    color: #666666;
    margin-right: 58px;
    cursor: pointer;
  }
  .on {
    color: #166dba;
    font-weight: 600;
    border-bottom: 2px solid #166dba;
  }
  .content {
    display: flex;
  }
  background-color: #fbfbfb;
}
.crumbs {
  span{
    cursor: pointer;
  }
  background: #fbfbfb;
  padding: 28px 0;
  font-size: 14px;
  color: #999999;
}
.banner {
  .title {
    position: absolute;
    top: 50%;
    left: 26%;
    transform: translate(-50%, -50%);
    p {
      font-size: 24px;
    }
    h5 {
      font-size: 48px;
      margin-bottom: 10px;
    }
    color: white;
  }
  width: 100%;
  height: 460px;
  background: url("../../images/home/case/p1.png") no-repeat;
  background-size: cover;
  background-position-x: 50%;
  position: relative;
}
.content {
  width: 1200px;
  margin: auto;
}
.container {
  overflow: hidden;
  width: 100%;
}
</style>
